<i18n>
{
  "de": {
    "pageTitle": "Liegenschaften verwalten",
    "identifierTitle": "Identifikation",
    "descriptionTitle": "Bezeichnung",
    "zipCodeTitle": "Postleitzahl",
    "municipalityTitle": "Gemeinde",
    "selectAllLabel": "Alle auswählen",
    "selectNoneLabel": "Auswahl entfernen",
    "missingArea": "Fläche fehlt",
    "areaTitle": "EBF",
    "ghgTitle": "Scope 1-2 Emissionen",
    "sanitizedTitle": "Problemerkennung",
    "buttonTitleGeneral": "Allgemeine Aktionen:",
    "buttonTitleExternalSources": "Externe Quellen (GIS, usw.) neu abfragen:",
    "updateGisDataButton": "Alle",
    "updateGWRButton": "GWR",
    "updateCadastreButton": "Parzellen",
    "updateRoofDataButton": "Dachflächen",
    "updateGeothermal": "Zulässigkeit Wärmenutzung",
    "updateEnergyGrids": "Wärmenetze und Wärmequellen",
    "simulateButton": "Neu simulieren",
    "queueSimulationButton": "Neu simulieren (asynchron)",
    "updateLatestReida": "Reida aktualisieren",
    "sanityCheckButton": "Problembehebung",
    "deleteBuilding": "Liegenschaft(en) löschen",
    "addBuildingsButton": "Liegenschaften hochladen",
    "state": {
      "WAITING": "Warte",
      "PENDING": "Aktualisiere...",
      "SUCCESS": "Erfolgreich"
    },
    "returnButton": "Zurück",
    "confirmDelete": "Wollen Sie diese Liegenschaft(en) wirklich löschen?"
  }
}
</i18n>

<template>
  <MainLayout :ready="portfolioReady && buildingsForListLoaded" class="admin-portfolio-buildings-page">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />

      <ButtonWrapper>
        <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }">
          {{ $t('returnButton') }}
        </router-link>
      </ButtonWrapper>

      <ButtonWrapper>
        <router-link
          class="button"
          :to="{ name: 'adminPortfolioAddBuildings', params: { portfolio_id: portfolio.id } }"
          >{{ $t('addBuildingsButton') }}</router-link
        >
        <ExportPortfolioBuildings :portfolio="portfolio" />
      </ButtonWrapper>

      <DetailList has-header class="buildings-table">
        <template #header>
          <span></span>
          <span>{{ $t('identifierTitle') }}</span>
          <span>{{ $t('descriptionTitle') }}</span>
          <span>{{ $t('zipCodeTitle') }}</span>
          <span>{{ $t('municipalityTitle') }}</span>
          <HeaderEntry :title="$t('areaTitle')" unit="m²" class="align-right" />
          <HeaderEntry :title="$t('ghgTitle')" unit="kg CO₂e" class="align-right" />
          <span class="align-right">{{ $t('sanitizedTitle') }}</span>
          <span></span>
          <span></span>
        </template>

        <li v-for="(building, index) in buildingsForList" :key="index">
          <span>
            <CheckInput v-model="selectedBuildings[index]" :edit="true" />
          </span>
          <span
            ><router-link
              :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: building.id } }"
              >{{ building.identifier }}</router-link
            ></span
          >
          <span>{{ building.description }}</span>
          <span>{{ building.zip_code }}</span>
          <span>{{ building.municipality }}</span>
          <span class="align-right">
            <span v-if="building.energy_area" class="numbers">{{ building.energy_area }}</span>
            <span v-else class="missing-data">{{ $t('missingArea') }}</span>
          </span>
          <span class="align-right">
            <span v-if="building.specific_s12e" class="numbers">{{ formatNumber(building.specific_s12e, 0) }}</span>
            <span v-else>-</span>
          </span>
          <span class="align-right">
            <span v-if="faultyBuildingIds.includes(building.id)">
              <img src="/icons/x.svg" />
            </span>
            <span v-else>
              <img src="/icons/check.svg" />
            </span>
          </span>
          <span class="errors">
            <div v-if="building.gwr_state !== 'OK'" class="gis-errors">{{ $t(`_gwrState.${building.gwr_state}`) }}</div>
            <div v-if="building.gwr_state === 'OK' && building.cadastre_state !== 'OK'" class="gis-errors">
              {{ $t(`_cadastreState.${building.cadastre_state}`) }}
            </div>
            <div v-if="building.gwr_state === 'OK' && building.geothermal_state !== 'OK'" class="gis-errors">
              {{ $t(`_geothermalState.${building.geothermal_state}`) }}
            </div>
            <div v-if="building.gwr_state === 'OK' && building.energy_grids_state !== 'OK'" class="gis-errors">
              {{ $t(`_energyGridsState.${building.energy_grids_state}`) }}
            </div>
            <div v-if="building.gwr_state === 'OK' && building.roof_state !== 'OK'" class="gis-errors">
              {{ $t(`_roofState.${building.roof_state}`) }}
            </div>
          </span>
          <span v-if="buildingState[index]">
            {{ $t(`state.${buildingState[index]}`) }}
          </span>
          <span v-else></span>
        </li>
      </DetailList>

      <ButtonWrapper>
        <button type="button" class="button" @click="onSelectAll">{{ $t('selectAllLabel') }}</button>
        <button type="button" class="button" @click="onSelectNone">{{ $t('selectNoneLabel') }}</button>
      </ButtonWrapper>

      <p class="button-title">{{ $t('buttonTitleGeneral') }}</p>
      <ButtonWrapper>
        <button type="button" class="button" @click="onSimulate">{{ $t('simulateButton') }}</button>
        <button type="button" class="button" @click="onQueueSimulation">{{ $t('queueSimulationButton') }}</button>
        <button type="button" class="button" @click="onUpdateLatestReida">{{ $t('updateLatestReida') }}</button>
        <button type="button" class="button" @click="onSanityCheck">{{ $t('sanityCheckButton') }}</button>
        <button type="button" class="button" @click="onDelete">{{ $t('deleteBuilding') }}</button>
      </ButtonWrapper>

      <p class="button-title">{{ $t('buttonTitleExternalSources') }}</p>
      <ButtonWrapper>
        <button type="button" class="button" @click="onUpdateGisData">{{ $t('updateGisDataButton') }}</button>
        <button type="button" class="button" @click="onUpdateGWR">{{ $t('updateGWRButton') }}</button>
        <button type="button" class="button" @click="onUpdateCadastre">{{ $t('updateCadastreButton') }}</button>
        <button type="button" class="button" @click="onUpdateRoof">{{ $t('updateRoofDataButton') }}</button>
        <button type="button" class="button" @click="onUpdateGeothermal">{{ $t('updateGeothermal') }}</button>
        <button type="button" class="button" @click="onUpdateEnergyGrids">{{ $t('updateEnergyGrids') }}</button>
      </ButtonWrapper>
    </template>
  </MainLayout>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioBuildingsForListMixin from '@/pages/vuex-mixins/PortfolioBuildingsForListMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'
import ExportPortfolioBuildings from '@/components/admin-portfolios/ExportPortfolioBuildings.vue'

import compassApi from '@/services/compass-api.js'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  name: 'adminPortfolioBuildings',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsForListMixin, // Provides: buildingsForList, buildingsForListLoading, buildingsForListLoaded
  ],

  components: {
    HeaderEntry,
    MainLayout,
    DetailList,
    CheckInput,
    ButtonWrapper,
    ExportPortfolioBuildings,
    PageTitle,
  },

  data() {
    return {
      faultyBuildingIds: [],
      selectedBuildings: [],
      buildingState: [],
    }
  },

  created() {
    this.loadFaultyBuildingIds()
  },

  methods: {
    ...mapActions({
      updateGisData: 'building/updateGisData',
      queryGwr: 'building/queryGwr',
      queryCadastre: 'building/queryCadastre',
      queryRoofTiles: 'building/queryRoofTiles',
      queryGeothermal: 'building/queryGeothermal',
      queryEnergyGrids: 'building/queryEnergyGrids',
      simulateBuilding: 'building/simulateBuilding',
      queueSimulationBuilding: 'building/queueSimulationBuilding',
      updateLatestReida: 'building/updateLatestReida',
      sanityCheckBuilding: 'building/sanityCheckBuilding',
      deleteBuilding: 'building/deleteBuilding',
    }),

    async loadFaultyBuildingIds() {
      return compassApi.call(`/portfolios/${this.portfolio_id}/buildings/faulty?query={}`).then((response) => {
        this.faultyBuildingIds = response.buildings.map((b) => b.id)
        return response
      })
    },

    onSelectAll() {
      this.selectedBuildings = this.buildingsForList.map((b) => true)
    },

    onSelectNone() {
      this.selectedBuildings = this.buildingsForList.map((b) => false)
    },

    setSelectedPending() {
      this.buildingState = this.selectedBuildings.map((s) => (s ? 'WAITING' : null))
    },

    processBuildings: async function (consumer) {
      this.setSelectedPending()
      for (let buildingIdx = 0; buildingIdx < this.buildingsForList.length; buildingIdx++) {
        if (this.selectedBuildings[buildingIdx]) {
          const building = this.buildingsForList[buildingIdx]
          Vue.set(this.buildingState, buildingIdx, 'PENDING')
          await consumer(building)
          Vue.set(this.buildingState, buildingIdx, 'SUCCESS')
        }
      }
      await this.loadFaultyBuildingIds()
    },

    async onUpdateGisData() {
      await this.processBuildings((b) => this.updateGisData({ buildingId: b.id, portfolioId: this.portfolio.id }))
    },

    async onUpdateGWR() {
      await this.processBuildings((b) => this.queryGwr({ id: b.id }))
    },

    async onUpdateCadastre() {
      await this.processBuildings((b) => this.queryCadastre({ id: b.id }))
    },

    async onUpdateRoof() {
      await this.processBuildings((b) => this.queryRoofTiles({ id: b.id }))
    },

    async onUpdateGeothermal() {
      await this.processBuildings((b) => this.queryGeothermal({ id: b.id }))
    },

    async onUpdateEnergyGrids() {
      await this.processBuildings((b) => this.queryEnergyGrids({ id: b.id }))
    },

    async onSimulate() {
      await this.processBuildings((b) => this.simulateBuilding({ buildingId: b.id, portfolioId: this.portfolio.id }))
    },

    async onQueueSimulation() {
      await this.processBuildings((b) =>
        this.queueSimulationBuilding({ buildingId: b.id, portfolioId: this.portfolio.id })
      )
    },

    async onUpdateLatestReida() {
      await this.processBuildings((b) => this.updateLatestReida({ buildingId: b.id, portfolioId: this.portfolio.id }))
    },

    async onSanityCheck() {
      await this.processBuildings((b) => this.sanityCheckBuilding({ buildingId: b.id, portfolioId: this.portfolio.id }))
    },

    async onDelete() {
      if (confirm(this.$t('confirmDelete'))) {
        await this.processBuildings((b) => this.deleteBuilding({ buildingId: b.id, portfolioId: this.portfolio.id }))
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.admin-portfolio-buildings-page {
  & .detail-list.buildings-table > ul > li {
    display: grid;
    grid-template-columns: 32px 1.5fr 4fr 1fr 2fr 1fr 1fr 2fr 4fr;
    gap: 0 var(--box-padding-h);
  }

  & .missing-data {
    color: var(--error-color);
  }

  & .unsure-data {
    color: var(--warning-color);
  }

  & .button-title {
    margin-top: 30px;
  }

  & .errors,
  & .errors * {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<style scoped>
.align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
